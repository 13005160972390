export enum SUBMIT_MODE {
    "CREATE" = "CREATE",
    "EDIT" = "EDIT",
    "DELETE" = "DELETE",
    "READONLY" = "READONLY",
}

export enum QUERY_OPERATOR {
    OperatorEqual = "eq", // 等於
    OperatorNotEqual = "neq", // 不等於
    OperatorLike = "like", // 模糊
    OperatorNotLike = "not like", // 不模糊
    OperatorGreater = "gt", // 大於
    OperatorLess = "lt", // 小於
    OperatorGTE = "gte", // 大於等於
    OperatorLTE = "lte", // 小於等於
    OperatorIN = "in", // 陣列
    OperatorNotIN = "not in", // 不在陣列
    OperatorIsNull = "is null", // 為空
    OperatorIsNotNull = "is not null", // 不為空
    OperatorNot = "not", // 非
    OperatorBetween = "between", // 區間
}

export enum RES_STATUS {
    "SUCCESS" = 1,
    "FAIL" = -1,
    "CLIENT_ERROR" = 0,
}

export enum MAP_COLUMN_NAME {
    "account" = "帳號",
    "plan_name" = "方案名稱",
    "org_name" = "組織名稱",
    "name" = "組織名稱",
    "service_name" = "服務名稱",
    "function_key" = "服務代碼",
    "id" = "ID",
    "contact_email" = "Email",
    "username" = "使用者名稱",
    "title" = "標題",
    "description" = "描述",
    "content" = "內容",
    "game_name" = "遊戲名稱",
    "CPU_Archit" = "CPU 架構",
    "algorithm" = "演算法",
    "game_id" = "遊戲 ID",
    "system" = "作業系統",
    "version" = "版本號",
    "checksum" = "checksum",
    "download_url" = "下載的網址",
    "project" = "專案",
    "default_game" = "預設遊戲",
    "Auth_count" = "授權數量",
    "setting_type" = "設定類型",
    "setting_history" = "設定歷史",
    "subject" = "主旨",
    "serial_number" = "組織 ID",
}

export enum TOKEN_ERROR_KEY {
    "REFRESH_TOKEN_EXPIRED" = "TOKEN.REFRESH_ERR",
    "ACCESS_TOKEN_EXPIRED" = "TOKEN.INVALID_ERROR",
}

export enum MIME_TYPES {
    pdf = "application/pdf",
    txt = "text/plain",
    doc = "application/msword",
    docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    mp3 = "audio/mpeg",
    mpeg = "video/mpeg",
    mp4 = "video/mp4",
    ppt = "application/vnd.ms-powerpoint",
    pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    wav = "audio/wav",
    xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    zip = "application/zip",
    csv = "text/csv",
}

export enum UPLOAD_STATUS {
    SUCCESS_100 = "100",
}

export enum UPLOAD_POLL_TIME {
    M_SEC_0 = 0,
    M_SEC_1000 = 1000,
}

export enum JOB_STATUS {
    processing, // 等待處理/處理中
    success,
}

export enum JOB_DETAIL_STATUS {
    idle = "idle",
    pending = "pending",
    processing = 0,
    success = 1,
}
