import { apiSlice } from "../../../services/api/apiSlice";
import { ICurrentRolesRes, ICurrentUserRes, ILoginReq, ILoginRes } from "../types/interfaces";

//import { LoginReq, RegisterReq } from "../types/interfaces";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<ILoginRes, ILoginReq>({
            query: (credential) => ({
                url: "/login",
                method: "POST",
                body: { ...credential },
            }),
            invalidatesTags: ["Auth"],
        }),
        //登入用以及拿目前使用者資訊，因為/me沒有出 get
        getCurrentUser: builder.query<ICurrentUserRes, any>({
            query: () => ({
                url: "/current_user",
                method: "POST",
            }),
            providesTags: ["Auth"],
        }),
        getCurrentRoles: builder.query<ICurrentRolesRes, void>({
            query: () => ({
                url: "/current_user_roles",
                method: "POST",
            }),
            providesTags: ["Auth"],
        }),
        //googleSignUp: builder.mutation({
        //    query: (credential: ISocialSignUpReq) => ({
        //        url: "/google_signup",
        //        method: "POST",
        //        body: { ...credential },
        //    }),
        //    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        //        try {
        //            const { data } = await queryFulfilled;

        //            dispatch(postLogin(data.access_token));
        //        } catch (err) {
        //            console.log(err);
        //        }
        //    },

        //    invalidatesTags: ["Auth"],
        //}),
    }),
});

export const { useLoginMutation, useGetCurrentUserQuery, useGetCurrentRolesQuery } = authApiSlice;
