import { QUERY_OPERATOR } from "../types/enum";
import { IGetAllArgs, IQueryField, ISearchField, ISortBy } from "../types/interfaces";
import { checkObjHasValue } from "./obj";

// const getSearchQueryStr = (obj: ISearchField | null): string => {
//     if (!obj) {
//         return "";
//     }
//     let queryStrArr = [];

//     for (let key in obj) {
//         if (obj.hasOwnProperty(key) && !!obj[key]) {
//             let queryString = `&filter={"field":"${key}","operator":"like","value":"${obj[key]}"}`;
//             queryStrArr.push(queryString);
//         }
//     }
//     return queryStrArr.join("");
// };

const getSearchQueryStr = (obj: ISearchField | null): string => {
    if (!obj) {
        return "";
    }
    let queryStrArr = [];
    let queryStringStart = "&filters=";
    // 檢查是否為 UUID
    const isUUID = (value: string): boolean => {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(value);
    };

    const isBoolean = (value: string): boolean => {
        return typeof value === "boolean";
    };

    for (let key in obj) {
        if (obj.hasOwnProperty(key) && !!obj[key]) {
            let operator = QUERY_OPERATOR.OperatorLike;
            let value = obj[key];
            if (isUUID(obj[key] as string) || isBoolean(obj[key] as string)) {
                operator = QUERY_OPERATOR.OperatorEqual; // UUID 要用 equal, 不然會回傳失敗
            }
            if (isBoolean(obj[key] as string)) {
                operator = QUERY_OPERATOR.OperatorEqual;
                if (obj[key] === "true") {
                    value = 1;
                } else {
                    value = 0;
                }
            }
            let queryString = `${key}:${operator}:${value}`;
            queryStrArr.push(queryString);
        }
    }
    return queryStringStart + encodeURIComponent(queryStrArr.join("&"));
};
const getOperatorQueryStr = (obj: IQueryField | null, operator: QUERY_OPERATOR): string => {
    if (!obj) {
        return "";
    }
    let queryStrArr = [];

    for (let key in obj) {
        if (obj.hasOwnProperty(key) && !!obj[key]) {
            let queryString = `&filter={"field":"${key}","operator":"${operator}","value":"${obj[key]}"}`;
            queryStrArr.push(queryString);
        }
    }
    return queryStrArr.join("");
};

const getPageQueryStr = (pageConfig: Partial<IGetAllArgs>): string =>
    `?page=${pageConfig.page}&limit=${pageConfig.limit}`;

const getSortQueryStr = (sortConfig: ISortBy | ISortBy[] | undefined, defaultSort: ISortBy): string => {
    // const defaultSortStr = `&order_info=${defaultSort.field} ${defaultSort.value}`;
    const sortStr = defaultSort.field ? `&sort_by=${defaultSort.field}` : "";
    const orderStr = defaultSort.value ? `&order_by=${defaultSort.value}` : "";
    const defaultSortStr = `${sortStr}${orderStr}`;
    if (!sortConfig || !checkObjHasValue(sortConfig)) return defaultSortStr;

    // * advanced features
    if (Array.isArray(sortConfig)) {
        return "";
    }

    if (sortConfig.value === "none") {
        return defaultSortStr;
    }
    return `${defaultSortStr}`;
};

const getPramsSortQueryStr = (sortConfig: ISortBy | ISortBy[] | undefined, defaultSort: ISortBy): string => {
    const defaultSortStr = `${defaultSort.field} ${defaultSort.value}`;
    if (!sortConfig || !checkObjHasValue(sortConfig)) return defaultSortStr;

    // * advanced features
    if (Array.isArray(sortConfig)) {
        return "";
    }

    if (sortConfig.value === "none") {
        return defaultSortStr;
    }
    return `${sortConfig.field} ${sortConfig.value}`;
};

export { getOperatorQueryStr, getPageQueryStr, getSearchQueryStr, getSortQueryStr, getPramsSortQueryStr };
