import React, { isValidElement, useEffect } from "react";

import { Button } from "react-bootstrap";
import { DeepPartial, FieldValues, Resolver, SubmitHandler, UnpackNestedValue, useForm } from "react-hook-form";

type VerticalFromProps<TFormValues extends FieldValues> = {
    defaultValues?: UnpackNestedValue<DeepPartial<TFormValues>>;
    resolver?: Resolver<TFormValues>;
    children?: React.ReactNode;
    onSubmit: SubmitHandler<TFormValues>;
    formClass?: string;
    cancelCb?: () => void;
    hasBtn?: boolean;
    hasCancelBtn?: boolean;
    submitBtnLabel?: string;
    submitClasses?: string;
};

const VerticalForm = <TFormValues extends Record<string, any> = Record<string, any>>({
    defaultValues,
    resolver,
    children,
    onSubmit,
    formClass,
    cancelCb,
    hasCancelBtn = true,
    submitBtnLabel = "確認",
    hasBtn = true,
    submitClasses,
}: VerticalFromProps<TFormValues>) => {
    /*
     * form methods
     */

    const methods = useForm<TFormValues>({ defaultValues, resolver, mode: "all" });
    const { handleSubmit, register, control, formState, reset } = methods;
    const { errors, isDirty, isValid } = formState;

    const handleCancel = () => {
        if (cancelCb) {
            cancelCb();
        }
        if (defaultValues) {
            return reset(undefined, { keepDefaultValues: true });
        }
        reset();
    };

    useEffect(() => {
        if (defaultValues) {
            methods.reset(defaultValues);
        }
    }, [methods, defaultValues]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={formClass} noValidate>
            {Array.isArray(children)
                ? children.map((child) => {
                      return child.props && child.props.name
                          ? React.createElement(child.type, {
                                ...{
                                    ...child.props,
                                    register,
                                    key: child.props.name,
                                    errors,
                                    control,
                                },
                            })
                          : child;
                  })
                : isValidElement(children) &&
                  React.createElement(children.type, {
                      ...{
                          ...children.props,
                          register,
                          key: children.props.name,
                          errors,
                          control,
                      },
                  })}
            <div className={`text-md-end mb-0`}>
                {hasCancelBtn && cancelCb && (
                    <Button className='me-2' variant='secondary' type='button' onClick={handleCancel}>
                        取消
                    </Button>
                )}
                {hasBtn && (
                    <Button
                        className={submitClasses}
                        variant='info'
                        type='submit'
                        disabled={!isDirty || (isDirty && !isValid)}
                    >
                        {submitBtnLabel}
                    </Button>
                )}
            </div>
        </form>
    );
};

export default VerticalForm;
