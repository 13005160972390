import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInput";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styles from "./FormPhoneInput.module.scss";
import classNames from "classnames";
const FormPhoneInput = ({ control, name, label, labelClassName, errors, placeholder, action }: FormInputProps) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                const { onChange, value } = field;
                return (
                    <div className='mb-2'>
                        {label && (
                            <>
                                <Form.Label className={labelClassName}>{label}</Form.Label>
                                {action && action}
                            </>
                        )}
                        <PhoneInput
                            defaultCountry='TW'
                            value={value}
                            onChange={(value) => {
                                onChange(value ? value : "");
                            }}
                            placeholder={placeholder}
                            className={classNames(styles.customPhoneInput, "form-control", {
                                "is-invalid": errors && errors[name],
                            })}
                        />
                        {errors && errors[name] && (
                            <Form.Control.Feedback type='invalid' className='d-block'>
                                {errors[name]?.["message"] || errors[name]?.value?.["message"]}
                            </Form.Control.Feedback>
                        )}
                    </div>
                );
            }}
        />
    );
};
export default FormPhoneInput;
